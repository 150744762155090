/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import Description from "../components/description";
import FigureGrid from "../components/figureGrid";
import { Banner } from "../components/figures";
import IconGrid from "../components/iconGrid";
import Layout from "../components/layout";
import List from "../components/lists";
import Section from "../components/section";
import SEO from "../components/seo";

const Projects = props => {
    const { data, errors } = props;

    if (errors) {
        return <div>Error</div>;
    }

    const page = (data || {}).page;

    console.log(page);

    return (
        <Layout>
            <SEO title="Projects" />
            {/* <Section name="projects" hero></Section> */}
            <Section name="coding-languages" hero>
                <h1 sx={{ variant: "text.title.main" }}>{page.title}</h1>
                <p sx={{ pt: [5, 5, 6], variant: "text.body.main" }}>{page.codingSummary}</p>
                <IconGrid iconSize="5rem" />
            </Section>
            <Section name="hard-skills">
                <List
                    list={page._rawHardSkills.components}
                    title={page._rawHardSkills.title}
                    description={page._rawHardSkills.description}
                />
            </Section>
            <Section name="banner">
                <Banner fullscreen />
            </Section>
            <Section name="soft-skills">
                <List
                    list={page._rawSoftSkills.components}
                    title={page._rawSoftSkills.title}
                    description={page._rawSoftSkills.description}
                />
                {/* <FigureGrid list={page._rawImages} /> */}
            </Section>
        </Layout>
    );
};

export const query = graphql`
    query SkillsetPageQuery {
        page: sanitySkillsetPage(_id: { regex: "/(drafts.|)skillsetPage/" }) {
            title
            codingSummary
            _rawHardSkills
            banner {
                ...ImageWithPreview
            }
            _rawSoftSkills
            _rawImages
        }
    }
`;

export default Projects;

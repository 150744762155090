/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import BlockText from "./blockText";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

type ListProps = {
    title: string;
    // list : Array<>
    description?: string;
};

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const List = ({ title, description, list }: ListProps) => {
    console.log(list);

    return (
        <div
            className="list"
            sx={{
                display: description && ["block", "block", "flex"],
                justifyContent: "space-between",
                mt: [5, 5, 6]
            }}
        >
            <h2
                className="list-title"
                sx={{
                    flexBasis: "40%",
                    pb: title && [2, 2, 0],
                    variant: "text.heading.main"
                }}
            >
                {title}
            </h2>
            <div
                className="list-content"
                sx={{
                    flexBasis: "57.5%"
                }}
            >
                <p
                    className="list-description"
                    sx={{
                        variant: "text.body.main",
                        pb: description && 3
                    }}
                >
                    {description}
                </p>
                {list.map(item => (
                    <div key={item._key}>
                        <ListItem
                            heading={item.title}
                            body={item.description}
                            highlight={item.highlight}
                            color={item.color}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const ListItem = ({ heading, body, highlight, color }) => {
    const [ref, refInView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <motion.div
            ref={ref}
            initial={{ y: 100, skewY: 10, opacity: 0 }}
            animate={refInView ? { y: 0, skewY: 0, opacity: 1 } : { y: 100, skewY: 10, opacity: 0 }}
            transition={{ ...transition }}
        >
            <div
                className="list-item"
                sx={{ py: [1, 1, 3], display: "flex", alignItems: "center" }}
            >
                <div className="list__left" sx={{ flexBasis: highlight ? "70%" : "100%" }}>
                    <p
                        className="list-heading"
                        sx={{
                            variant: "text.body.main",
                            pb: body && 1
                        }}
                    >
                        {heading}
                    </p>
                    <p className="list-body" sx={{ variant: "text.body.alt" }}>
                        {body}
                    </p>
                </div>
                <div className="list__right" sx={{ flexBasis: highlight && "30%" }}>
                    <div
                        className="list-highlight"
                        sx={{
                            textAlign: "right",
                            variant: "text.body.main",
                            color: color && color
                        }}
                    >
                        {highlight}
                    </div>
                </div>
            </div>
            <hr />
        </motion.div>
    );
};

export default List;
